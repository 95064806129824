<template>
  <v-row align="center" class="justify-left mx-auto">
    <v-col cols="4">
      <GlobalMonthPicker />
    </v-col>
    <v-col cols="8"></v-col>

    <v-col cols="12" sm="2">
      <v-text-field v-model="search_id" label="Guía" clearable></v-text-field>
    </v-col>
    <v-col cols="12" sm="2">
      <v-text-field
        v-model="search_nombre"
        label="Cliente"
        clearable
      ></v-text-field>
    </v-col>
    <v-col cols="12" sm="2">
      <v-select
        clearable
        color
        ref="search_departamento"
        v-model="search_departamento"
        :items="departamentos"
        item-text="departamento"
        label="Departamento"
        @change="fnCargarMunicipiosFormReceptor()"
      ></v-select>
    </v-col>
    <v-col cols="12" sm="2">
      <v-select
        color
        ref="search_municipio"
        v-model="search_municipio"
        :items="municipios"
        item-text="nombre"
        item-value="nombre"
        label="Municipio"
        no-data-text="Selecciona un departamento"
      ></v-select>
    </v-col>

    <v-col sm="2" absolute right class="float-right">
      <v-select
        clearable
        color
        ref="estado"
        required
        v-model="search_status"
        :items="$store.state.estados"
        item-text="db_label"
        item-value="db_label"
        label="Estado"
      ></v-select>
    </v-col>
    <!-- <v-col cols="12" sm="2">
      <v-btn
        class="float-right"
        small
        @click="
          page = 1;
          getGuiasTablaDashboard();
        "
      >
        Búscar
      </v-btn>
    </v-col> -->

    <!-- /**
      BÚSCADOR EN TABLA */ -->

    <!-- /**
      FIN BÚSCADOR EN TABLA */ -->
    <!-- /**
      PAGINADOR DE TABLA */ -->
    <!-- <v-col cols="12" sm="12" class="d-flex justify-end"> </v-col> -->
    <!-- /**
      PAGINADOR DE TABLA */ -->
    <!-- /**
      TABLA DE GUIAS */ -->
    <v-col cols="12" sm="12">
      <v-card class="mx-auto" tile>
        <v-card-title>Guías</v-card-title>

        <v-data-table
          :loading="loadingTBL"
          :disable-sort="loadingTBL"
          :headers="headers"
          :items="tblGuias"
          :options.sync="options"
          :server-items-length="tblGuias.length"
          disable-pagination
          :hide-default-footer="true"
          no-data-text="Sin guias que mostrar"
          no-results-text="No se encontrarón guias"
        >
          <template
            v-slot:[`item.guiaReceptor.guiaReceptorMunicipio`]="{ item }"
          >
            <span :title="item.guiaReceptor.guiaReceptorMunicipio">{{
              item.guiaReceptor.guiaReceptorMunicipio | truncate(12, '...')
            }}</span>
          </template>

          <!-- /*** ID DE LA GUÍA */ -->
          <template v-slot:[`item._id`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span
                  @click="copyToClipBoard(item._id)"
                  @mouseover="(item.hover = true), fnGenerarQr(item._id)"
                  @mouseleave="item.hover = false"
                  v-bind="attrs"
                  v-on="on"
                  :class="
                    !item.hover
                      ? fnSearchColor(item.guiaStatus)
                      : fnSearchColor(item.guiaStatus, true)
                  "
                  >{{ item._id | truncate(19, '...') }}</span
                >
              </template>
              <span>{{ item._id }}</span>
              <v-img max-height="250" max-width="250" :src="qrimage"></v-img>
              <span v-if="item.quienCarga"
                >Cliente: {{ item.guiaReceptor.guiaReceptorNombre }}</span
              >
            </v-tooltip>
          </template>
          <!-- /*** ID DE LA GUÍA */ -->

          <!-- /*** ESTADO DE LA GUÍA */ -->
          <template v-slot:[`item.guiaStatus`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span
                  v-bind="attrs"
                  v-on="on"
                  :class="fnSearchColor(item.guiaStatus)"
                  >{{ item.guiaStatus | truncate(18, '..') }}</span
                >
              </template>
              <span>{{ item.guiaStatus }}</span>
              <span v-if="item.quienCarga"
                >Cargo: {{ item.quienCarga.pilotoNombre }} ({{
                  item.quienCarga.pilotoPlaca
                }}) <br />
                el: {{ fnGetDate(item.quienCarga.fecha) }}</span
              >
            </v-tooltip>
          </template>
          <!-- /*** ESTADO DE LA GUÍA */ -->

          <!-- /*** información de la persona que recibe */ -->
          <template v-slot:[`item.guiaReceptor.guiaReceptorNombre`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">{{
                  fnPrimeraLetraMayuscula(item.guiaReceptor.guiaReceptorNombre)
                }}</span>
              </template>
              <div>
                <small>Cliente:</small>
                {{ item.guiaReceptor.guiaReceptorNombre }}
                <br />
                <small>Dirección:</small>
                {{ item.guiaReceptor.guiaReceptorDireccion }}
                <br />
                <small>Teléfono:</small>
                {{ item.guiaReceptor.guiaReceptorTelefono }}
              </div>
            </v-tooltip>
          </template>
          <!-- /*** ESTADO DE LA GUÍA */ -->

          <template v-slot:[`item.createdAt`]="{ item }">
            <span :title="fnGetDate(item.createdAt)">{{
              fnGetDateWOtime(item.createdAt)
            }}</span>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <!-- /** VER INFORMACIÓN DEL PAQUETE */ -->
            <modalInformacionDeGuia :guiaid="item._id" />

            <!-- /** EDITAR PAQUETE */ -->
            <!-- /** OPCIONES DE ANULAR Y DEVOLVER PAQUETE */ -->
            <!-- <v-icon small @click="eliminarPaquete(item.id)">
              mdi-keyboard-return
            </v-icon>
            <v-icon small @click="eliminarPaquete(item.id)">
              mdi-cancel
            </v-icon> -->
            <v-btn
              title="Imprimir guía"
              x-small
              @click="fn_imprimir_guia(item)"
              text
            >
              <v-icon x-small> mdi-printer </v-icon>
            </v-btn>

            <modalAnularGuia
              :disableOption="fnCheckIfDisabledAnulado(item.guiaStatus)"
              :guiaid="item._id"
            />
          </template>
        </v-data-table>

        <!-- <v-card-actions v-if="tblGuias.length > 0">
          <v-btn small color="error" @click="eliminarTodosLosGuias">
            Remove All
          </v-btn>
        </v-card-actions> -->
      </v-card>
    </v-col>
    <v-col cols="6"></v-col>
    <v-col cols="6">
      <v-select
        v-model="pageSize"
        :items="pageSizes"
        label="Guías por página"
        @change="handlePageSizeChange"
      ></v-select>
      <v-pagination
        v-model="page"
        :length="totalPages"
        total-visible="10"
        next-icon="mdi-menu-right"
        prev-icon="mdi-menu-left"
        @input="handlePageChange"
      ></v-pagination>
    </v-col>
    <!-- /**
      TABLA DE GUIAS */ -->
  </v-row>
</template>

<script>
import GlobalMonthPicker from '../../components/GlobalMonth.vue';
import departamentos from './../../components/departamentos.js';
import QRCode from 'qrcode';
import { imprimir_guia_js } from '../manifiestos/imprimir_guia';
import modalInformacionDeGuia from './modalInformacionDeGuiaParaTabla';
import modalAnularGuia from './modalAnularGuia';
import { mapState, mapGetters, mapActions } from 'vuex';
export default {
  name: 'tblGuias-list',
  components: {
    modalInformacionDeGuia,
    modalAnularGuia,
    GlobalMonthPicker,
  },
  props: ['prop_fecha', 'prop_departamento', 'prop_placa', 'guiaStatus'],
  data() {
    return {
      departamentos: departamentos,
      municipios: [],
      /** */
      qrimage: '',
      /** */
      loadingTBL: false,
      options: {},
      /** */
      localFecha: null,
      dialog: false,
      /** */
      tblGuias: [],
      search_id: '',
      search_nombre: '',
      search_departamento: '',
      search_municipio: '',
      search_status: '',
      headers: [
        {
          text: 'Guia',
          align: 'start',
          sortable: true,
          value: '_id',
        },

        {
          text: 'Departamento',
          value: 'guiaReceptor.guiaReceptorDepartamento',
          sortable: true,
        },
        {
          text: 'Municipio',
          value: 'guiaReceptor.guiaReceptorMunicipio',
          sortable: true,
        },
        {
          text: 'Cliente',
          value: 'guiaReceptor.guiaReceptorNombre',
          sortable: true,
        },
        { text: 'Estado', value: 'guiaStatus', sortable: true },
        {
          text: 'Creada',
          value: 'createdAt',
          sortable: true,
        },
        { text: '', value: 'actions', sortable: false },
      ],

      page: 1,
      totalPages: 0,
      pageSize: 5,

      pageSizes: [5, 15, 50],
    };
  },
  methods: {
    fnPrimeraLetraMayuscula(guiaReceptorNombre) {
      const mySentence = guiaReceptorNombre;
      const words = mySentence.split(' ');

      for (let i = 0; i < words.length; i++) {
        words[i] = words[i][0].toUpperCase() + words[i].substr(1);
      }

      return words.join(' ');
    },
    /** función para verificar si la guia puede ser anulada */
    fnCheckIfDisabledAnulado(guiaStatus) {
      console.log('check status', guiaStatus);
      if (guiaStatus == 'Entregado') {
        return true;
      }
      if (guiaStatus == 'Anulado') {
        return true;
      }
      if (guiaStatus == 'Con devolución') {
        return true;
      }
      return false;
    },
    fnCargarMunicipiosFormReceptor() {
      if (this.search_departamento != '' && this.search_departamento != null) {
        console.log('my way ' + this.search_departamento);
        let municipios = this.departamentos.filter(
          (dpto) => dpto.departamento == this.search_departamento
        );
        // console.log(rol[0]);
        console.log(municipios[0].municipios);
        this.municipios = municipios[0].municipios;
      } else {
        this.municipios = [];
        this.search_municipio = '';
      }
    },
    copyToClipBoard(textToCopy) {
      this.$notify({
        group: 'auth',
        type: 'notify',
        title: 'Número de guía copiado',
        text: `No. de guía: ${textToCopy}`,
        duration: 3000,
      });
      navigator.clipboard.writeText(textToCopy);
    },
    ...mapActions('Gpsguias', {
      getguias: 'get',
      findguias: 'find',
      patchGuia: 'patch',
      crearGuia: 'create',
    }),
    /** funcion para obtener color segun estado */
    fnSearchColor(estado, flag_color_oscuro = null) {
      if (estado && flag_color_oscuro == true) {
        let store_estado = this.$store.state.estados.filter(
          (store_estado) => store_estado.db_label == estado
        );
        // console.log(rol[0]);
        return store_estado[0].color + '--text text--darken-4';
      }
      if (estado && flag_color_oscuro == null) {
        let store_estado = this.$store.state.estados.filter(
          (store_estado) => store_estado.db_label == estado
        );
        // console.log(rol[0]);
        return store_estado[0].color + '--text';
      } else {
        return 'info';
      }
    },
    /** funcion para obtener color segun estado */
    getParams() {
      /**
       * calculo de fechas para muestra de guias segun creadas
       */
      if (this.$store.state.globalMonth) {
        let fecha_split = this.prop_fecha.split('-');

        let mes = fecha_split[1] - 1;
        let ano = fecha_split[0];

        var firstDay = new Date(ano, mes, 1);
        var lastDay = new Date(ano, mes + 1, 1);
      } else {
        var date = new Date();
        var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      }

      /**
       * configuración de parametros
       */
      const params = {};
      /**
       * paramentros de búsqueda por fecha
       */
      console.log(`First day ${firstDay} and last day ${lastDay}`);
      params['createdAt'] = {
        $gte: firstDay,
        $lte: lastDay,
      };

      if (this.search_status) {
        params['guiaStatus'] = this.search_status;
      }

      /**
       * parametros para busqueda de Guias
       */

      if (this.guiaStatus != '') {
        params['guiaStatus'] = this.guiaStatus;
      }

      if (this.search_id) {
        if (this.search_id.match(/^[0-9a-fA-F]{24}$/)) {
          // Yes, it's a valid ObjectId, proceed with `findById` call.
          params['$or'] = [
            { _id: this.search_id.trim() },
            {
              guiaCodigo: {
                $regex: this.search_id.trim(),
              },
            },
          ];
        } else {
          params['guiaCodigo'] = {
            $regex: this.search_id.trim(),
          };
        }
      }

      if (this.search_nombre) {
        params['guiaReceptor.guiaReceptorNombre'] = {
          $regex: this.search_nombre.trim(),
          $options: 'i',
        };
      }

      if (this.search_departamento) {
        params['guiaReceptor.guiaReceptorDepartamento'] = {
          $regex: this.search_departamento,
          // $options: 'igm',
        };
      }

      if (this.search_municipio) {
        params['guiaReceptor.guiaReceptorMunicipio'] = {
          $regex: this.search_municipio,
          // $options: 'igm',
        };
      }

      if (this.prop_departamento) {
        params[
          'guiaReceptor.guiaReceptorDepartamento'
        ] = this.prop_departamento;
      }

      if (this.prop_placa) {
        params['quienCarga.pilotoPlaca'] = this.prop_placa;
      }

      if (this.prop_piloto) {
        params['quienCarga.pilotoNombre'] = this.prop_piloto;
      }
      /**
       * $SORT param
       */
      if (
        this.options.sortBy &&
        this.options.sortBy.length === 1 &&
        this.options.sortDesc.length === 1
      ) {
        let sorting = {};
        let flag = this.options.sortDesc[0] ? 1 : -1;
        sorting[this.options.sortBy[0].toString()] = flag;
        params['$sort'] = sorting;
      } else {
        params['$sort'] = { createdAt: -1 };
      }

      /**
       * paramentros de búsqueda por paginación (obligatorios)
       */
      params['$limit'] = this.pageSize;
      params['$skip'] = (this.page - 1) * this.pageSize;

      /**
       * fin de parametros para búsqueda de Guias
       */

      return params;
    },

    getGuiasTablaDashboard() {
      console.log(this.getParams());
      this.loadingTBL = true;
      this.findguias({
        query: this.getParams(),
      }).then((result) => {
        /**
         * arreglo de data de la tabla:
         */
        console.log(result.data);
        this.tblGuias = result.data;
        /**
         * datos de paginación
         */
        this.totalPages = Math.ceil(
          parseInt(result.total) / parseInt(this.pageSize)
        );
        /** */
        this.loadingTBL = false;
      });
    },

    handlePageChange(value) {
      this.page = value;
      this.getGuiasTablaDashboard();
    },

    handlePageSizeChange(size) {
      this.pageSize = size;
      this.page = 1;
      this.getGuiasTablaDashboard();
    },

    refreshList() {
      this.getGuiasTablaDashboard();
    },

    fnGetDate(date) {
      let formatedDate = moment(date).utc().format('DD-MMMM-YYYY hh:mm a');
      return formatedDate;
    },
    fnGetDateWOtime(date) {
      let formatedDate = moment(date).utc().format('DD-MMMM-YYYY');
      return formatedDate;
    },
    fnGenerarQr(datos) {
      let info = {
        A: datos,
        B: '5b4514ca-6acd-4675-8b22-a50b9f418cd0',
        C: 'Catherine Mccullough',
      };

      let stringifyinfo = JSON.stringify(info);
      console.log(typeof stringifyinfo);
      /**
       *
       * generar codigo qr
       */
      let vm = this;
      QRCode.toDataURL(stringifyinfo, function (err, url) {
        vm.qrimage = url;
      });
    },
    fn_imprimir_guia(item) {
      imprimir_guia_js(item, item.guiaReceptor, item.guiaDescripcion);
    },
  },
  mounted() {
    this.getGuiasTablaDashboard();
    /** event listeners | GPSGUIAS */
    const { Gpsguias } = this.$FeathersVuex.api;
    Gpsguias.on('patched', (gpsGuia) => {
      console.log('Gpsguia patched');
      this.getGuiasTablaDashboard();
    });
    Gpsguias.on('created', (gpsGuia) => {
      this.getGuiasTablaDashboard();
    });
    /** event listeners | GPSGUIAS */
  },
  watch: {
    search_id() {
      this.page = 1;
      this.getGuiasTablaDashboard();
    },
    search_nombre() {
      this.page = 1;
      this.getGuiasTablaDashboard();
    },
    search_departamento() {
      this.page = 1;
      this.getGuiasTablaDashboard();
    },
    search_municipio() {
      this.page = 1;
      this.getGuiasTablaDashboard();
    },
    search_status() {
      this.page = 1;
      this.getGuiasTablaDashboard();
    },
    options: {
      handler() {
        this.getGuiasTablaDashboard();
      },
      deep: true,
    },
    prop_fecha(newValue, oldValue) {
      this.getGuiasTablaDashboard();
      this.localFecha = newValue;
      console.log(this.localFecha);
    },
  },
};
</script>

<style>
.list {
  max-width: 750px;
}
</style>
