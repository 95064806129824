<template>
  <div>
    <v-fab-transition>
      <v-btn
        @click="DialogCrearGuia = true"
        class="mb-25"
        dark
        absolute
        bottom
        right
        fab
        title="Crear guía"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-fab-transition>
    <v-dialog
      v-model="DialogCrearGuia"
      persistent
      scrollable
      transition="dialog-bottom-transition"
      max-width="90%"
    >
      <v-card color="white" icon="mdi-email-box">
        <v-card-title>
          <small class="text-body-1">Crear guías a través de formulario</small>
        </v-card-title>
        <v-card-text>
          <v-form>
            <!--  stepper FORMULARIO DE CREACIÓN stepper -->
            <v-stepper v-model="e1">
              <!-- ENCABEZADO STEPPER -->
              <v-stepper-header>
                <v-stepper-step :complete="e1 > 1" editable step="1">
                  Crear guía
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step :complete="e1 > 2" editable step="2">
                  ¿Quíen recibe?
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step editable step="3"> Cobro </v-stepper-step>
              </v-stepper-header>

              <v-stepper-items>
                <v-stepper-content step="1">
                  <!-- /** INICIO DE FORMULARIO (SECCIÓN crear guía) */ -->
                  <v-container class="py-0">
                    <v-row>
                      <v-col cols="12" md="6">
                        <!-- <v-text-field
                          clearable
                          color="purple darken-2"
                          title="Sí se deja en blanco, se genera de forma automática."
                          label="Número de guióa (opcional)"
                          v-model="newGuia.guiaCodigo"
                        /> -->
                      </v-col>
                      <!-- checkbox -->
                      <!-- ¿Es un envío de recolección? -->
                      <v-col cols="12" md="3">
                        <!-- <v-checkbox
                          v-model="newGuia.guiaConRecoleccion"
                          label="¿Hay que irlo a traer?"
                          color="indigo"
                          :value="false"
                          hide-details
                        ></v-checkbox> -->
                      </v-col>
                      <!-- <v-col cols="12" md="3">
                        <v-checkbox
                          v-model="newGuia.guiaConRecoleccion"
                          label="Transporte externo"
                          color="indigo"
                          :value="false"
                          hide-details
                        ></v-checkbox>
                      </v-col> -->
                      <hr />

                      <!-- <v-col cols="12" md="5">
                      <v-text-field
                        clearable
                        color="purple darken-2"
                        label="Nombre de quien envia*"
                        class="required"
                        ref="guiaEmisorNombre"
                        required
                        v-model="newGuia.guiaEmisor.guiaEmisorNombre"
                      />
                    </v-col>

                    <v-col cols="12" md="5">
                      <v-text-field
                        clearable
                        color="purple darken-2"
                        label="Número de telefono"
                        class="required"
                        ref="guiaEmisorTelefono"
                        required
                        v-model="newGuia.guiaEmisor.guiaEmisorTelefono"
                      />
                    </v-col> -->

                      <!-- (checkbox) INICIO INFORMACIÓN SÍ LA GUIA ES DE RECOLECCIÓN -->
                      <v-col
                        v-show="newGuia.guiaConRecoleccion"
                        cols="12"
                        md="3"
                      >
                        <v-text-field
                          clearable
                          color="purple darken-2"
                          label="Número de telefono 2"
                          v-model="newGuia.guiaEmisor.guiaEmisorTelefono2"
                        />
                      </v-col>

                      <v-col
                        v-show="newGuia.guiaConRecoleccion"
                        cols="12"
                        md="12"
                      >
                        <v-text-field
                          clearable
                          color="purple darken-2"
                          label="Dirección de recolección"
                          v-model="newGuia.guiaEmisor.guiaEmisorDireccion"
                        />
                      </v-col>
                      <!-- inicio DROP DOWN EMISOR -->
                      <v-col
                        v-show="newGuia.guiaConRecoleccion"
                        cols="12"
                        md="4"
                      >
                        <v-select
                          color
                          v-model="newGuia.guiaEmisor.guiaEmisorDepartamento"
                          :items="departamentos"
                          item-text="departamento"
                          filled
                          label="Departamento"
                          @change="fnCargarMunicipiosFormEmisor()"
                        ></v-select>
                      </v-col>
                      <!-- fin DROP DOWN EMISOR -->
                      <v-col
                        v-show="newGuia.guiaConRecoleccion"
                        cols="12"
                        md="4"
                      >
                        <v-select
                          color
                          v-model="newGuia.guiaEmisor.guiaEmisorMunicipio"
                          :items="municipios"
                          filled
                          label="Municipio"
                        ></v-select>
                      </v-col>

                      <v-col
                        v-show="newGuia.guiaConRecoleccion"
                        cols="12"
                        md="4"
                      >
                        <v-text-field
                          clearable
                          color="purple darken-2"
                          label="Código postal"
                          type="number"
                        />
                      </v-col>
                      <!-- FIN INFORMACIÓN SÍ LA GUIA ES DE RECOLECCIÓN -->
                      <!-- <v-col
                        cols="12"
                        style="border-radius: 15px"
                        class="primary lighten-4"
                        >¿Qué envias?</v-col
                      > -->
                      <!-- TIPO DE ENTREGA (CAJAS, PAQUETES, SOBRES, ETC) -->

                      <v-col cols="6">
                        <v-row>
                          <v-col
                            cols="12"
                            style="border-radius: 15px"
                            class="primary lighten-4"
                            >¿Qué envias?</v-col
                          >
                          <v-col cols="6">
                            <v-select
                              clearable
                              color
                              ref="tipo"
                              required
                              v-model="newDescripcion.tipo"
                              :items="tiposDeEmpaque"
                              item-text="tipo"
                              item-value="tipo"
                              label="Empaque"
                            ></v-select>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              clearable
                              color="purple darken-2"
                              label="Peso (lb)"
                              type="number"
                              class="required"
                              ref="peso"
                              required
                              v-model="newDescripcion.peso"
                            />
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              clearable
                              color="purple darken-2"
                              label="Dimensiones (cm)"
                              type="text"
                              class="required"
                              ref="dimension"
                              required
                              v-model="newDescripcion.dimension"
                            />
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              clearable
                              color="purple darken-2"
                              label="cantidad"
                              type="number"
                              class="required"
                              ref="cantidad"
                              required
                              v-model="newDescripcion.cantidad"
                            />
                          </v-col>
                          <v-col cols="9">
                            <v-textarea
                              color="purple darken-2"
                              label="Descripción y observaciones del envío*"
                              ref="descripcion"
                              required
                              v-model="newDescripcion.descripcion"
                            />
                          </v-col>
                          <v-col cols="3">
                            <v-checkbox
                              label="Frágil"
                              v-model="newDescripcion.fragil"
                            ></v-checkbox>
                          </v-col>

                          <v-col cols="12">
                            <v-btn
                              ref="btnAgregarBultoDetalle"
                              @click="fnAddNewDescripcion()"
                              dark
                              :color="flag_btnAgregarDetalleColor"
                              class="float-right"
                            >
                              <span>Agregar detalle</span>
                              <v-icon title="agrega detalle">mdi-plus</v-icon>
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-col>

                      <!-- /**  DETALLES DE EMPAQUE(s) AGREGADOS */ -->
                      <v-col cols="6" class="pl-5">
                        <v-row>
                          <v-col
                            cols="12"
                            style="border-radius: 15px"
                            class="primary lighten-4"
                            >Detalle de la guía</v-col
                          >
                          <v-col cols="12">
                            <v-list class="mb-2">
                              <!-- /** inicio  */ -->
                              <v-list-item
                                outlined
                                elevation="10"
                                three-line
                                v-for="(
                                  paquete, index
                                ) in newGuia.guiaDescripcion"
                                :key="index"
                              >
                                <!-- prettier-ignore -->
                                <v-list-item-content>
                                 
                                  <v-list-item-title class="text-h5 mb-1">
                                     <div v-if="paquete.descripcion" class="">
                                      {{ paquete.descripcion }}
                                    </div>
                                  </v-list-item-title>
                                  
                                  <v-list-item-subtitle>
                                  <span class="mr-2" v-if="paquete.tipo">Empaque: <strong style="font-weight: 700;">{{ paquete.tipo }}</strong></span>
                                  <span class="mr-2" v-if="paquete.cantidad">Cantidad: <strong style="font-weight: 700;">{{ paquete.cantidad }}</strong></span>
                                  <span class="mr-2" v-if="paquete.peso">Peso: <strong style="font-weight: 700;">{{ paquete.peso }}</strong> lb</span>
                                  <span class="mr-2" v-if="paquete.dimension">Dimensión: <strong style="font-weight: 700;">{{ paquete.dimension }}</strong> cm</span>
                                  <span class="mr-2" v-if="paquete.fragil">Frágil: <strong style="font-weight: 700;">{{convertBoolean(paquete.fragil)}}</strong></span>
                                  </v-list-item-subtitle>
                                
                                </v-list-item-content>

                                <v-btn
                                  @click="fnEliminarBulto(index)"
                                  small
                                  text
                                  title="Eliminar bulto"
                                >
                                  <v-icon color="red">mdi-delete</v-icon>
                                </v-btn>
                              </v-list-item>
                              <!-- /** fin */ -->
                            </v-list>
                          </v-col>
                        </v-row>
                      </v-col>
                      <!-- /**  DETALLES DE EMPAQUE(s) AGREGADOS */ -->
                    </v-row>
                  </v-container>
                  <!-- /** (1) FIN DEL FROMULARIO DE FORMULARIO (SECCIÓN crear guía) */ -->
                </v-stepper-content>

                <v-stepper-content step="2">
                  <!-- INICIO DE FORMULARIO (INFORMACIÓN DE QUIEN RECIBE -->
                  <v-container class="py-0">
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-text-field
                          clearable
                          color="purple darken-2"
                          label="Nombre de la persona quien recibe *"
                          class="required"
                          ref="guiaReceptorNombre"
                          required
                          v-model="newGuia.guiaReceptor.guiaReceptorNombre"
                        />
                      </v-col>

                      <v-col cols="12" md="3">
                        <v-text-field
                          :rules="[
                            (v) =>
                              v.length == 8 ||
                              'El número de teléfono debe de tener 8 dígitos',
                          ]"
                          type="number"
                          clearable
                          color="purple darken-2"
                          label="Número de teléfono 1"
                          class="required"
                          ref="guiaReceptorTelefono"
                          required
                          v-model="newGuia.guiaReceptor.guiaReceptorTelefono"
                        />
                      </v-col>

                      <v-col cols="12" md="3">
                        <v-text-field
                          clearable
                          color="purple darken-2"
                          label="Número de teléfono 2"
                          v-model="newGuia.guiaReceptor.guiaReceptorTelefono2"
                        />
                      </v-col>
                      <v-col cols="12" md="12">
                        <v-text-field
                          clearable
                          color="purple darken-2"
                          label="Dirección de envío"
                          class="required"
                          ref="guiaReceptorDireccion"
                          required
                          v-model="newGuia.guiaReceptor.guiaReceptorDireccion"
                        />
                      </v-col>

                      <v-col cols="12" md="4">
                        <!-- prettier-ignore -->
                        <v-select
                          color
                          class="required"
                          ref="guiaReceptorDepartamento"
                          required
                          v-model="newGuia.guiaReceptor.guiaReceptorDepartamento"
                          :items="departamentos"
                          item-text="departamento"
                          filled
                          label="Departamento"
                          @change="fnCargarMunicipiosFormReceptor()"
                        ></v-select>
                      </v-col>

                      <v-col cols="12" md="4">
                        <v-select
                          color
                          class="required"
                          ref="guiaReceptorMunicipio"
                          required
                          v-model="newGuia.guiaReceptor.guiaReceptorMunicipio"
                          :items="municipios"
                          item-text="nombre"
                          item-value="nombre"
                          filled
                          label="Municipio"
                        ></v-select>
                      </v-col>

                      <v-col cols="12" md="4">
                        <v-text-field
                          clearable
                          color="purple darken-2"
                          label="Código postal"
                          type="number"
                        />
                      </v-col>

                      <!-- <v-col cols="12">
                        <v-textarea
                          color="purple darken-2"
                          label="Descripción y observaciones del envío"
                          v-model="newGuia.guiaDescripcion"
                        />
                      </v-col> -->
                    </v-row>
                  </v-container>
                  <!-- FIN FORMULARIO (INFORMACÍON DE QUIEN RECIBE -->
                </v-stepper-content>

                <v-stepper-content step="3">
                  <v-container class="py-0">
                    <v-row>
                      <v-col cols="12" md="3">
                        <v-text-field
                          v-model="newGuia.guiaCodigo"
                          type="text"
                          clearable
                          color="green"
                          label="Número de factura"
                        />
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-radio-group v-model="newGuia.opcionPago">
                          <v-radio
                            v-for="opcionPago in opcionesDePago"
                            :key="opcionPago.pago"
                            :label="`${opcionPago.pago}`"
                            :value="opcionPago.pago"
                          ></v-radio>
                        </v-radio-group>
                      </v-col>
                      <v-col
                        v-if="newGuia.opcionPago == 'Por cobrar'"
                        cols="12"
                        md="3"
                      >
                        <v-text-field
                          v-model="newGuia.opcionPagoMonto"
                          type="number"
                          step=".1"
                          clearable
                          color="green"
                          label="Monto (Q)"
                        />
                      </v-col>

                      <v-col cols="12" class="text-right">
                        <!-- <v-btn
                          color="success"
                          min-width="150"
                          @click="fnCrearGuia()"
                        >
                          Crear guía
                        </v-btn> -->
                      </v-col>
                    </v-row>
                  </v-container>
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
            <!-- stepper fin FORMULARIO DE CREACIÓN stepper -->
          </v-form>
        </v-card-text>

        <v-card-actions class="" v-if="e1 == '1'">
          <!-- /** BOTON SIGUEINTE EN DETALLE DE BULTOS */ -->
          <v-btn
            v-if="newGuia.guiaDescripcion.length == 0"
            color="grey"
            class=""
            @click="fnValidarDetallesEnFormulario1()"
          >
            siguiente
          </v-btn>
          <!-- /** BOTON SIGUEINTE EN DETALLE DE BULTOS */ -->
          <v-btn
            v-if="newGuia.guiaDescripcion.length >= 1"
            color="primary"
            @click="e1 = 2"
          >
            siguiente
          </v-btn>
          <!-- /** BOTON SIGUEINTE EN DETALLE DE BULTOS */ -->
          <v-spacer></v-spacer>
          <v-btn class="float-right" text @click="DialogCrearGuia = false"
            >Cerrar ventana</v-btn
          >
        </v-card-actions>
        <v-card-actions class="" v-if="e1 == '2'">
          <v-btn @click="e1 = 1" text> Atrás </v-btn>
          <!-- /** BOTON SIGUIENTE EN DETALLE DE LA PERSONA QUE RECIBE */ -->
          <v-btn
            v-if="
              !newGuia.guiaReceptor.guiaReceptorNombre ||
              !newGuia.guiaReceptor.guiaReceptorTelefono ||
              newGuia.guiaReceptor.guiaReceptorTelefono.length != 8 ||
              !newGuia.guiaReceptor.guiaReceptorDireccion ||
              !newGuia.guiaReceptor.guiaReceptorDepartamento ||
              !newGuia.guiaReceptor.guiaReceptorMunicipio
            "
            color="grey"
            @click="fnValidarFormulario()"
          >
            Siguiente
          </v-btn>
          <v-btn
            v-if="
              newGuia.guiaReceptor.guiaReceptorNombre &&
              newGuia.guiaReceptor.guiaReceptorTelefono &&
              newGuia.guiaReceptor.guiaReceptorTelefono.length == 8 &&
              newGuia.guiaReceptor.guiaReceptorDireccion &&
              newGuia.guiaReceptor.guiaReceptorDepartamento &&
              newGuia.guiaReceptor.guiaReceptorMunicipio
            "
            color="primary"
            @click="e1 = 3"
          >
            Siguiente
          </v-btn>

          <!-- /** BOTON SIGUIENTE EN DETALLE DE LA PERSONA QUE RECIBE */ -->

          <v-spacer></v-spacer>
          <v-btn class="float-right" text @click="DialogCrearGuia = false"
            >Cerrar ventana</v-btn
          >
        </v-card-actions>
        <v-card-actions class="" v-if="e1 == '3'">
          <!-- <v-btn color="primary" @click="e1 = 1"> inicio </v-btn> -->

          <v-btn @click="e1 = 2" text> Atrás </v-btn>
          <v-btn color="success" @click="fnCrearGuia()"> Crear guía </v-btn>
          <v-spacer></v-spacer>
          <v-btn class="float-right" text @click="DialogCrearGuia = false"
            >Cerrar ventana</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import departamentos from './../../components/departamentos.js';
import { mapState, mapGetters, mapActions } from 'vuex';
import GmapCustomMarker from 'vue2-gmap-custom-marker';
import { gmapApi } from 'vue2-google-maps';
export default {
  components: {
    'gmap-custom-marker': GmapCustomMarker,
  },
  data() {
    return {
      DialogCrearGuia: false,
      flag_btnAgregarDetalleColor: 'purple darken-2',
      newDescripcion: {
        tipo: null,
        peso: null,
        dimension: null,
        cantidad: null,
        fragil: false,
        descripcion: null,
      },
      //
      contadorDetalleGuias: 0,
      tiposDeEmpaque: [
        { tipo: 'sobre', icon: null },
        { tipo: 'caja/paquete', icon: null },
      ],
      opcionesDePago: [{ pago: 'Cancelado' }, { pago: 'Por cobrar' }],
      //
      //
      e1: 1,
      //
      showByIndex: null,
      coordenadasEntrega: { lat: 15.0032599, lng: -90.4136375 },
      coordenadasRecoleccion: { lat: 15.0032599, lng: -90.4136375 },
      mapCenter: { lat: 15.0032599, lng: -90.4136375 },
      mapTypeId: 'roadmap',
      mapZoom: 8,
      /** */
      departamentos: departamentos,
      municipios: [],
      newGuia: {
        guiaCodigo: null,
        guiaStatus: 'Pendiente de asignación',
        tipoDeCarga: 'Producción',
        guiaConRecoleccion: false,
        opcionPago: 'Cancelado',
        opcionPagoMonto: null,
        guiaDescripcion: [],
        guiaEmisor: {
          guiaEmisorNombre: '',
          guiaEmisorTelefono: '',
          guiaEmisorDireccion: '',
          guiaEmisorCoordenadas: null,
          guiaEmisorDepartamento: null,
          guiaEmisorMunicipio: null,
        },
        guiaReceptor: {
          guiaReceptorNombre: '',
          guiaReceptorTelefono: '',
          guiaReceptorDireccion: '',
          guiaReceptorCoordenadas: null,
          guiaReceptorDepartamento: null,
          guiaReceptorMunicipio: null,
        },
      },
    };
  },
  methods: {
    ...mapActions('Gpsguias', {
      getguias: 'get',
      findguias: 'find',
      patchGuia: 'patch',
      crearGuia: 'create',
    }),
    fnEliminarBulto(index) {
      this.newGuia.guiaDescripcion.splice(index, 1);
    },
    fnAddNewDescripcion() {
      if (!this.fnValidarDetallesEnFormulario1(true)) {
      } else {
        this.newGuia.guiaDescripcion.push({
          tipo: this.newDescripcion.tipo,
          peso: this.newDescripcion.peso,
          dimension: this.newDescripcion.dimension,
          cantidad: this.newDescripcion.cantidad,
          fragil: this.newDescripcion.fragil,
          descripcion: this.newDescripcion.descripcion,
        });

        /**
         * notificacion
         */
        this.$notify({
          group: 'foo',
          type: 'success',
          title: 'Se agregó un bulto',
          text: this.newDescripcion.descripcion,
          duration: 3000,
        });
        /**
         * contador de bultos en guía
         *
         */
        this.contadorDetalleGuias++;
        /**
         * reset
         */
        this.newDescripcion.tipo = null;
        this.newDescripcion.peso = null;
        this.newDescripcion.dimension = null;
        this.newDescripcion.cantidad = null;
        this.newDescripcion.fragil = false;
        this.newDescripcion.descripcion = null;
      }
    },
    updateCoordinatesCarga(location) {
      this.newGuia.guiaEmisor.guiaEmisorCoordenadas = {
        type: 'GeoJSON',
        coordinates: [location.latLng.lng(), location.latLng.lat()],
      };
      this.coordenadasRecoleccion.lat = location.latLng.lat();
      this.coordenadasRecoleccion.lng = location.latLng.lng();
    },
    updateCoordinates(location) {
      this.newGuia.guiaReceptor.guiaReceptorCoordenadas = {
        type: 'GeoJSON',
        coordinates: [location.latLng.lng(), location.latLng.lat()],
      };
      this.coordenadasEntrega.lat = location.latLng.lat();
      this.coordenadasEntrega.lng = location.latLng.lng();
    },

    fnCargarMunicipiosFormEmisor() {
      let departamentos = this.departamentos.filter(
        (dpto) =>
          dpto.departamento == this.newGuia.guiaEmisor.guiaEmisorDepartamento
      );
      // console.log(rol[0]);
      this.municipios = departamentos[0].municipios;
    },
    fnCargarMunicipiosFormReceptor() {
      let municipios = this.departamentos.filter(
        (dpto) =>
          dpto.departamento ==
          this.newGuia.guiaReceptor.guiaReceptorDepartamento
      );
      // console.log(rol[0]);
      console.log(municipios[0].municipios);
      this.municipios = municipios[0].municipios;
    },
    fnCrearGuia() {
      /** iniciación de validaciones de los inputs */
      let var_validation_flag_detalle_inputs = [false, ''];
      let var_validation_flag_rest_form_inputs = [false, ''];

      /** iniciación de validaciones de detalles de bultos agregados */
      let var_validation_flag_contador_de_bultos = false;

      /** validar el detalle de los bultos */
      var_validation_flag_detalle_inputs = this.fnValidarDetallesEnFormulario2();

      if (var_validation_flag_detalle_inputs[0]) {
        // validar si el detalle de bultos no solo estan llenos, sino hay mas de u no agregado
        if (this.newGuia.guiaDescripcion.length == 0) {
          this.e1 = 1;
          this.fnValidarDetallesLength();
        } else {
          /** validar el resto de los inputs obligatorios */
          var_validation_flag_rest_form_inputs = this.fnValidarFormulario();
          var_validation_flag_contador_de_bultos = true;
        }
      } else {
        this.e1 = 1;
      }
      //prettier-ignore
      if (var_validation_flag_rest_form_inputs[0] && var_validation_flag_contador_de_bultos) {
        this.crearGuia(this.newGuia)
          .then((result) => {
            console.log('Guía creada');
            this.$notify({
              group: 'foo',
              type: 'success',
              title: 'Guiá creada con exito',
              text: `No. de guía: ${result._id}`,
              duration: 15000,
            });
            this.DialogCrearGuia = false;

            /** reset información de guia */
            this.newGuia.guiaCodigo = null;
            this.newGuia.guiaStatus = 'Pendiente de asignación';
            this.newGuia.tipoDeCarga = 'Producción';
            this.newGuia.guiaConRecoleccion = false;
            this.newGuia.opcionPago = 'Cancelado';
            this.newGuia.opcionPagoMonto = null;
            this.newGuia.guiaDescripcion = [];
            this.newGuia.guiaEmisor.guiaEmisorNombre = '';
            this.newGuia.guiaEmisor.guiaEmisorTelefono = '';
            this.newGuia.guiaEmisor.guiaEmisorDireccion = '';
            this.newGuia.guiaEmisor.guiaEmisorCoordenadas = null;
            this.newGuia.guiaEmisor.guiaEmisorDepartamento = null;
            this.newGuia.guiaEmisor.guiaEmisorMunicipio = null;
            this.newGuia.guiaReceptor.guiaReceptorNombre = '';
            this.newGuia.guiaReceptor.guiaReceptorTelefono = '';
            this.newGuia.guiaReceptor.guiaReceptorDireccion = '';
            this.newGuia.guiaReceptor.guiaReceptorCoordenadas = null;
            this.newGuia.guiaReceptor.guiaReceptorDepartamento = null;
            this.newGuia.guiaReceptor.guiaReceptorMunicipio = null;
            this.e1 = 1;
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        if (this.newGuia.guiaDescripcion.length >= 1) {
          this.$notify({
            group: 'foo',
            type: 'error',
            title: 'Llena todos los datos',
            text: var_validation_flag_rest_form_inputs[1],
          });
        }
      }
      console.log('nueva guia: ', this.newGuia);
    },
    fnValidarFormulario() {
      // if (!this.newGuia.guiaEmisor.guiaEmisorNombre) {
      //   this.newGuia.guiaEmisor.guiaEmisorNombre = this.$store.state.auth.payload.gpsusuario.plan.empresa;

      //   // this.e1 = 1;
      //   // let vm = this;
      //   // setTimeout(() => {
      //   //   vm.$refs.guiaEmisorNombre.focus();
      //   // }, 420);
      //   // return [false, 'Ingrese nombre de quien envía'];
      // }
      // if (!this.newGuia.guiaEmisor.guiaEmisorTelefono) {
      //   this.newGuia.guiaEmisor.guiaEmisorTelefono = this.$store.state.auth.payload.gpsusuario.plan.telefono;
      //   // this.e1 = 1;
      //   // let vm = this;
      //   // setTimeout(() => {
      //   //   vm.$refs.guiaEmisorTelefono.focus();
      //   // }, 420);
      //   // return [false, 'Ingrese teléfono de quien envía'];
      // }

      if (!this.newGuia.guiaReceptor.guiaReceptorNombre) {
        this.e1 = 2;
        let vm = this;
        setTimeout(() => {
          vm.$refs.guiaReceptorNombre.focus();
        }, 420);
        return [false, 'Ingrese nombre de quien recibe'];
      }
      if (!this.newGuia.guiaReceptor.guiaReceptorTelefono) {
        this.e1 = 2;
        let vm = this;
        setTimeout(() => {
          vm.$refs.guiaReceptorTelefono.focus();
        }, 420);
        return [false, 'Ingrese teléfono de quien recibe'];
      }
      if (!this.newGuia.guiaReceptor.guiaReceptorDireccion) {
        this.e1 = 2;
        let vm = this;
        setTimeout(() => {
          vm.$refs.guiaReceptorDireccion.focus();
        }, 420);
        return [false, 'Ingrese dirección donde recibe'];
      }
      if (!this.newGuia.guiaReceptor.guiaReceptorDepartamento) {
        this.e1 = 2;
        let vm = this;
        setTimeout(() => {
          vm.$refs.guiaReceptorDepartamento.focus();
          vm.$refs.guiaReceptorDepartamento.activateMenu();
        }, 420);

        return [false, 'Elige departamento de envío'];
      }
      if (!this.newGuia.guiaReceptor.guiaReceptorMunicipio) {
        this.e1 = 2;
        let vm = this;
        setTimeout(() => {
          vm.$refs.guiaReceptorMunicipio.focus();
          vm.$refs.guiaReceptorMunicipio.activateMenu();
        }, 420);

        return [false, 'Elige municipio de envío'];
      } else {
        return [true, ''];
      }
    },
    fnValidarDetallesLength() {
      this.$notify({
        group: 'foo',
        type: 'warn',
        title: 'Agrega al menos un bulto a la guía',
        text: '',
        duration: 5000,
      });
      let vm = this;
      setTimeout(() => {
        vm.$refs.btnAgregarBultoDetalle.$el.focus();
        vm.flag_btnAgregarDetalleColor = '#ffb648';
      }, 420);

      setTimeout(() => {
        vm.flag_btnAgregarDetalleColor = 'purple darken-2';
      }, 5420);
    },
    fnValidarDetallesEnFormulario1(flagBtn = null) {
      let response = this.fnValidarDetallesEnFormulario2();
      if (response[0] == false) {
        this.$notify({
          group: 'foo',
          type: 'error',
          title: 'Agrega al menos un bulto a la guía',
          text: response[1],
          duration: 5000,
        });
      } else {
        if (this.newGuia.guiaDescripcion.length == 0) {
          this.e1 = 1;
          if (!flagBtn) {
            this.fnValidarDetallesLength();
          } else {
            return true;
          }
        } else {
          if (flagBtn) {
            return true;
          }
        }
      }
    },
    fnValidarDetallesEnFormulario2() {
      if (this.newGuia.guiaDescripcion.length >= 1) {
        return [true, ''];
      }
      // vacio al no ser obligatorio
      // if (!this.newDescripcion.tipo) {
      //   this.e1 = 1;
      //   let vm = this;
      //   setTimeout(() => {
      //     vm.$refs.tipo.focus();
      //     vm.$refs.tipo.activateMenu();
      //   }, 420);

      //   return [false, 'Elige tipo de empaque'];
      // }
      // vacio al no ser obligatorio
      // if (!this.newDescripcion.peso) {
      //   this.e1 = 1;
      //   let vm = this;
      //   setTimeout(() => {
      //     vm.$refs.peso.focus();
      //   }, 420);
      //   return [false, 'Ingrese peso'];
      // }
      // vacio al no ser obligatorio
      // if (!this.newDescripcion.dimension) {
      //   this.e1 = 1;
      //   let vm = this;
      //   setTimeout(() => {
      //     vm.$refs.dimension.focus();
      //   }, 420);
      //   return [false, 'Ingrese dimensión'];
      // }
      // vacio al no ser obligatorio
      // if (!this.newDescripcion.cantidad) {
      //   this.e1 = 1;
      //   let vm = this;
      //   setTimeout(() => {
      //     vm.$refs.cantidad.focus();
      //   }, 420);
      //   return [false, 'Ingrese cantidad'];
      // }
      if (!this.newDescripcion.descripcion) {
        this.e1 = 1;
        let vm = this;
        setTimeout(() => {
          vm.$refs.descripcion.focus();
        }, 420);
        return [false, 'Debes de ingresar una descripción del bulto'];
      }
      return [true, ''];
    },
    convertBoolean(boolean) {
      return boolean == true ? 'si' : 'no';
    },
  },
  computed: {
    checkIfCanGoNextClientDetails() {
      return false;
    },
  },
};
</script>

<style scoped>
.vue-map-container {
  height: 40vh;
  width: 100%;
  display: inline-block;
}
.required label::after {
  content: '*';
  color: red !important;
}
</style>
