import { jsPDF } from 'jspdf';
import { base64logo } from './base64logo';
import QRCode from 'qrcode';
import moment from 'moment';

async function imprimir_guia_js(guia, receptor, bultos) {
  let height = 12;
  let width = 12;

  let marginLeft = 1;
  let marginRight = 1;

  const doc = new jsPDF({
    orientation: 'l',
    unit: 'cm',
    format: [height, width],
  });

  let y_cursor = 0;

  /** cursor init */
  y_cursor++;
  /**
   ***********************
   *Número de guía
   ***********************
   */
  doc.setFontSize(10);
  doc.text('Guía: ' + guia.guiaCodigo, 1, y_cursor);
  y_cursor = y_cursor + 0.5;
  doc.setFontSize(8);
  doc.text('ID: ' + guia._id, 1, y_cursor);

  /**
   ***********************
   *Guía body
   ***********************
   */
  //init sets'
  doc.setFont('arial', 'bold');
  doc.setFontSize(10);
  /**
   ***********************
   *Nombre de quien recibe
   ***********************
   */
  y_cursor++;
  let doc_label_nombre = 'Para: ';
  let doc_label_nombre_dimensiones = doc.getTextDimensions(doc_label_nombre);
  doc.setFont('arial', '');
  doc.text(doc_label_nombre, 1, y_cursor);
  doc.setFont('arial', 'bold');
  doc.text(
    receptor.guiaReceptorNombre,
    doc_label_nombre_dimensiones.w + 1,
    y_cursor
  );

  /**
   ***********************
   *Número de teléfono
   ***********************
   */
  y_cursor = y_cursor + 0.5;
  let doc_label_telefono = 'Teléfono: ';
  let doc_label_telefono_dimensiones = doc.getTextDimensions(
    doc_label_telefono
  );
  doc.setFont('arial', '');
  doc.text(doc_label_telefono, 1, y_cursor);
  doc.setFont('arial', 'bold');
  doc.text(
    receptor.guiaReceptorTelefono,
    doc_label_telefono_dimensiones.w + 1,
    y_cursor
  );

  /**
   ***********************
   *Dirección de la guía
   ***********************
   */
  //siguiente linea
  y_cursor = y_cursor + 0.5;

  //
  let doc_label_direccion = 'Dirección: ';
  let doc_label_direccion_dimensiones = doc.getTextDimensions(
    doc_label_direccion
  );
  //dividir direccion en espacios no mas grandes de "8"
  // prettier-ignore
  let doc_split_direccion = doc.splitTextToSize(receptor.guiaReceptorDireccion, (width-doc_label_direccion_dimensiones.w-1));
  //
  doc.setFont('arial', '');
  doc.text(doc_label_direccion, 1, y_cursor);
  doc.setFont('arial', 'bold');
  doc.text(
    doc_split_direccion,
    doc_label_direccion_dimensiones.w + 1,
    y_cursor
  );

  let doc_direccion_dimensiones = doc.getTextDimensions(doc_split_direccion);
  /**
   ***********************
   *Departamento y municipio
   ***********************
   */
  //siguiente linea
  y_cursor = y_cursor + doc_direccion_dimensiones.h;

  /** etiqueta y dimensiones */
  let doc_label_departamento = 'Depto: ';
  let doc_label_departamento_dimensiones = doc.getTextDimensions(
    doc_label_departamento
  );
  //
  let var_horizontal_multi_line_depto_municipio = '';
  //
  doc.setFont('arial', '');
  doc.text(doc_label_departamento, 1, y_cursor);
  //
  doc.setFont('arial', 'bold');
  doc.text(
    receptor.guiaReceptorDepartamento,
    doc_label_departamento_dimensiones.w + 1,
    y_cursor
  );
  // prettier-ignore
  var_horizontal_multi_line_depto_municipio = doc.getTextDimensions(doc_label_departamento + receptor.guiaReceptorDepartamento);
  /** etiqueta y dimensiones */
  let doc_label_municipio = 'Municipio: ';
  let doc_label_municipio_dimensiones = doc.getTextDimensions(
    doc_label_municipio
  );
  //
  doc.setFont('arial', '');
  doc.text(
    doc_label_municipio,
    var_horizontal_multi_line_depto_municipio.w + 1.1,
    y_cursor
  );
  //
  doc.setFont('arial', 'bold');
  doc.text(
    receptor.guiaReceptorMunicipio,
    var_horizontal_multi_line_depto_municipio.w +
      doc_label_municipio_dimensiones.w +
      1,
    y_cursor
  );
  /**
   ***********************
   *Departamento y municipio (FIN)
   ***********************
   */

  /**
   *
   * HR | separador
   */
  y_cursor = y_cursor + 0.5;
  doc.line(1, y_cursor, width - 1, y_cursor, 'f');

  /**
   ***********************
   *Detalle de los bultos [ENCABEZADO]
   ***********************
   */
  //siguiente linea
  y_cursor = y_cursor + 0.5;

  /** etiqueta y dimensiones */
  let doc_label_detalles = 'Bultos: ';
  let doc_label_detalles_dimensiones = doc.getTextDimensions(
    doc_label_detalles
  );
  //
  doc.setFont('arial', '');
  doc.text(doc_label_detalles, 1, y_cursor);
  //
  doc.setFont('arial', 'bold');
  doc.text(
    bultos.length.toString(),
    doc_label_detalles_dimensiones.w + 1,
    y_cursor
  );

  /**
   ***********************
   *Detalle de los bultos [CUERPO]
   ***********************
   */
  let detallesContador = 0;
  bultos.forEach((bulto) => {
    detallesContador++;
    //siguiente linea
    y_cursor = y_cursor + 0.7;
    // DETALLLE TIPO
    let var_horizontal_multi_line_detalle = '';
    let var_horizontal_multi_line_detalle_dimensiones = '';

    doc.setFont('arial', 'bold');
    if (bulto.tipo) {
      doc.text(detallesContador + '). ' + bulto.tipo + ', ', 1, y_cursor);
      // prettier-ignore
      var_horizontal_multi_line_detalle = var_horizontal_multi_line_detalle + detallesContador + '). '+bulto.tipo+', ';
    }
    // prettier-ignore
    var_horizontal_multi_line_detalle_dimensiones = doc.getTextDimensions(var_horizontal_multi_line_detalle);

    //DETALLE CANTIDAD
    if (bulto.cantidad) {
      doc.setFont('arial', '');
      let doc_label_cantidad = 'Cant: ';
      // prettier-ignore
      doc.text(doc_label_cantidad+' ', var_horizontal_multi_line_detalle_dimensiones.w + 1, y_cursor);
      // prettier-ignore
      var_horizontal_multi_line_detalle = var_horizontal_multi_line_detalle + doc_label_cantidad+' ';
      // prettier-ignore
      var_horizontal_multi_line_detalle_dimensiones = doc.getTextDimensions(var_horizontal_multi_line_detalle);
      //
      doc.setFont('arial', 'bold');
      doc.text(
        bulto.cantidad.toString(),
        var_horizontal_multi_line_detalle_dimensiones.w + 1,
        y_cursor
      );
      // prettier-ignore

      var_horizontal_multi_line_detalle =
      var_horizontal_multi_line_detalle + bulto.cantidad.toString();
    }
    // prettier-ignore
    var_horizontal_multi_line_detalle_dimensiones = doc.getTextDimensions(var_horizontal_multi_line_detalle);

    //DETALLE PESO
    if (bulto.peso) {
      doc.setFont('arial', '');
      let doc_label_peso = ', Peso: ';
      // prettier-ignore
      doc.text(doc_label_peso+' ', var_horizontal_multi_line_detalle_dimensiones.w + 0.80, y_cursor);
      // prettier-ignore
      var_horizontal_multi_line_detalle = var_horizontal_multi_line_detalle + doc_label_peso;
      // prettier-ignore
      var_horizontal_multi_line_detalle_dimensiones = doc.getTextDimensions(var_horizontal_multi_line_detalle);
      //
      doc.setFont('arial', 'bold');
      doc.text(
        bulto.peso.toString(),
        var_horizontal_multi_line_detalle_dimensiones.w + 1,
        y_cursor
      );
      // prettier-ignore
      var_horizontal_multi_line_detalle =
      var_horizontal_multi_line_detalle + bulto.peso.toString();
    }
    // prettier-ignore
    var_horizontal_multi_line_detalle_dimensiones = doc.getTextDimensions(var_horizontal_multi_line_detalle);

    //DETALLE DIMENSIONES
    doc.setFont('arial', '');
    if (bulto.dimension) {
      let doc_label_dimensiones = ', Dim: ';
      // prettier-ignore
      doc.text(
      doc_label_dimensiones,
      var_horizontal_multi_line_detalle_dimensiones.w + 0.8,
      y_cursor
    );
      // prettier-ignore
      var_horizontal_multi_line_detalle = var_horizontal_multi_line_detalle + doc_label_dimensiones;
      // prettier-ignore
      var_horizontal_multi_line_detalle_dimensiones = doc.getTextDimensions(var_horizontal_multi_line_detalle);
      //
      doc.setFont('arial', 'bold');
      doc.text(
        bulto.dimension.toString(),
        var_horizontal_multi_line_detalle_dimensiones.w + 1,
        y_cursor
      );
      // prettier-ignore
      var_horizontal_multi_line_detalle =
      var_horizontal_multi_line_detalle + bulto.dimension.toString();
    }
    // prettier-ignore
    var_horizontal_multi_line_detalle_dimensiones = doc.getTextDimensions(var_horizontal_multi_line_detalle);

    //siguiente linea
    y_cursor = y_cursor + 0.5;
    //dividir direccion en espacios no mas grandes de "8"
    let doc_split_detalle = doc.splitTextToSize(bulto.descripcion, 8);
    //
    let doc_label_descripcion = 'Detalle: ';
    let doc_label_detalle_dimensiones = doc.getTextDimensions(
      doc_label_descripcion
    );
    //
    doc.setFont('arial', '');
    doc.text(doc_label_descripcion, 1, y_cursor);
    doc.setFont('arial', 'bold');
    doc.text(doc_split_detalle, doc_label_detalle_dimensiones.w + 1, y_cursor);
  });

  /**
   ***********************
   * FIN Detalle de los bultos [CUERPO]
   ***********************
   */

  /**
   *
   * CODIGO QR DE LA GUIA
   */
  let pedido = {
    A: guia,
    B: receptor.guiaReceptorNombre,
    C: receptor.guiaReceptorDepartamento,
  };
  var qrCodeImage = await QRCode.toDataURL(JSON.stringify(pedido));
  doc.addImage(qrCodeImage, 'jpeg', 8.8, 8.8, 3, 3, 'QR', 'FAST', 0);

  /**
   *
   * LOGO DEL SOFTWARE
   */
  doc.addImage(base64logo, 'jpeg', 9.5, 0.2, 1.9, 0.7, 'logo', 'FAST', 0);

  /**
   * hora de impresion
   */

  doc.setFont('arial', '');
  doc.setFontSize(6);
  doc.text(moment().format('DD-MM-yyyy hh:mm a'), 11.6, 7, { angle: 90 });

  /**
   * Copyright ©
   */

  doc.setFont('arial', '');
  doc.setFontSize(6);
  let ano = moment().format('yyyy');
  // prettier-ignore
  doc.text(`© ${ano} Datakalan S.A. Guatemala, Guatemala.`, 1, 11.6);

  /** print in a new tab */
  doc.output('dataurlnewwindow');
}

export { imprimir_guia_js };
