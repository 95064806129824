<template>
  <v-dialog scrollable v-model="dialog" persistent max-width="420px">
    <template v-slot:activator="{ on, attrs }">
      <!-- <v-btn color="primary" dark v-bind="attrs" v-on="on"> Open Dialog </v-btn> -->
      <v-btn
        x-small
        text
        v-bind="attrs"
        v-on="on"
        @click="fnConsultarInformacionDeGuia"
        :title="[disableOption ? 'No se puede anular' : 'Anular guía']"
        :disabled="disableOption"
        ><v-icon x-small> mdi-null </v-icon>
      </v-btn>
    </template>
    <v-card v-if="guiaid">
      <v-card-title>
        <span class="headline">¿Está seguro de querer anular esta guía? </span>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <v-list-item three-line>
              <v-list-item-content v-if="selectedGuia.guiaReceptor">
                <div class="text-overline mb-4">
                  ID:
                  <span
                    :class="fnSearchColor(selectedGuia.guiaStatus) + '--text'"
                  >
                    {{ selectedGuia._id }}</span
                  >
                </div>
                <v-list-item-title class="text-h5 mb-1">
                  A nombre de:
                </v-list-item-title>
                <v-list-item-subtitle>{{
                  selectedGuia.guiaReceptor.guiaReceptorNombre
                }}</v-list-item-subtitle>
                <v-list-item-title class="text-h5 mb-1">
                  Teléfono:
                </v-list-item-title>
                <v-list-item-subtitle>{{
                  selectedGuia.guiaReceptor.guiaReceptorTelefono
                }}</v-list-item-subtitle>
                <v-list-item-title class="text-h5 mb-1">
                  Dirección:
                </v-list-item-title>
                <v-list-item-subtitle>{{
                  selectedGuia.guiaReceptor.guiaReceptorDireccion
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item three-line>
              <v-list-item-content v-if="selectedGuia.guiaReceptor">
                <div class="text-overline mb-4">
                  Fecha creada:
                  <span> {{ formatDate(selectedGuia.createdAt) }}</span>
                </div>
                <div class="text-overline mb-4">
                  Ultima Actualización:
                  <span> {{ formatDate(selectedGuia.updatedAt) }}</span>
                </div>
                <v-list-item-title :class="['text-h5 mb-1']">
                  Estado:
                </v-list-item-title>
                <v-list-item-subtitle
                  :class="
                    fnSearchColor(selectedGuia.guiaStatus) + '--text mb-2'
                  "
                  >{{ selectedGuia.guiaStatus }}</v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <v-text-field
                  dense
                  type=""
                  error-count=""
                  required
                  placeholder="Motivo (obligatorio)"
                  label="Motivo (obligatorio):"
                  append-icon="mdi-null"
                  v-model="anular_motivo"
                  ref="anular_motivo"
                  outlined
                  :color="'primary'"
                ></v-text-field>

                <v-checkbox
                  label="Estoy de acuerdo."
                  v-model="anular_de_acuerdo_checkbox"
                ></v-checkbox
              ></v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <!-- <v-btn color="blue darken-1" text @click="dialog = false">
          Close
        </v-btn> -->
        <v-btn color="blue darken-1" text @click="dialog = false">
          Cancelar
        </v-btn>
        <v-btn
          :disabled="!anular_deacuerdo_y_motivo_flag"
          color="red darken-1"
          text
          @click="(dialog = false), fn_bd_anular_guia(selectedGuia._id)"
        >
          Anular guía
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import cuerpo from './modalInformacionDeGuiaCuerpo';
import { mapState, mapGetters, mapActions } from 'vuex';
export default {
  props: {
    disableOption: {
      type: Boolean,
    },
    guiaid: {
      type: String,
    },
  },
  components: {
    cuerpo,
  },
  data() {
    return {
      anular_motivo: '',
      anular_de_acuerdo_checkbox: false,
      selectedGuia: [],
      //
      loading: false,
      dialog: false,
      /** */
      admins: [
        ['Management', 'mdi-account-multiple-outline'],
        ['Settings', 'mdi-cog-outline'],
      ],
      cruds: [
        ['Create', 'mdi-plus-outline'],
        ['Read', 'mdi-file-outline'],
        ['Update', 'mdi-update'],
        ['Delete', 'mdi-delete'],
      ],
    };
  },
  methods: {
    ...mapActions('Gpsguias', {
      getguias: 'get',
      findguias: 'find',
      patchGuia: 'patch',
      crearGuia: 'create',
    }),
    fnConsultarInformacionDeGuia() {
      this.getguias(this.guiaid).then((result) => {
        this.selectedGuia = result;
        console.log('información de | modal guia | cuepro:');
        console.log(this.selectedGuia);
      });
    },
    fnSearchColor(estado) {
      console.log(estado);
      if (estado) {
        let store_estado = this.$store.state.estados.filter(
          (store_estado) => store_estado.db_label == estado
        );
        // console.log(rol[0]);
        console.log(store_estado[0].color);
        return store_estado[0].color;
      } else {
        return 'info';
      }
    },
    formatDate(fecha) {
      return moment(fecha).utc().format('DD-MM-YYYY hh:mm a');
    },
    fn_bd_anular_guia(id) {
      let anulado_detalle = {
        detalleAnulado: {
          motivo: this.anular_motivo,
        },
        guiaStatus: 'Anulado',
        accion: 'Anular',
      };
      this.patchGuia([id, anulado_detalle, {}])
        .then(() => {
          //this.$store.state.globalLoading = false;
          this.$notify({
            type: 'success',
            group: 'foo',
            title: 'Guia anulada',
            text: 'Se ha anulado la guía: ' + id,
          });
          this.anular_motivo = '';
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
  computed: {
    anular_deacuerdo_y_motivo_flag() {
      if (this.anular_motivo != '' && this.anular_de_acuerdo_checkbox == true) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    anular_de_acuerdo_checkbox(newValue) {
      if (newValue == true && this.anular_motivo == '') {
        this.$refs.anular_motivo.focus();
      }
    },
  },
};
</script>
<style scoped>
th,
td {
  border-bottom: 1px solid #ddd;
}
td:nth-child(2) {
  font-size: 12px;
}
tr:nth-child(odd) {
  background-color: rgba(255, 255, 255, 0.659);
}
tr:hover {
  background-color: rgba(201, 196, 196, 0.672);
}
.tdTituloDivision {
  font-size: 16px;
}
</style>
