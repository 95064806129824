<template>
  <v-dialog scrollable v-model="dialog" persistent max-width="100%">
    <template v-slot:activator="{ on, attrs }">
      <!-- <v-btn color="primary" dark v-bind="attrs" v-on="on"> Open Dialog </v-btn> -->
      <v-btn
        title="Ver información de la guía"
        x-small
        v-bind="attrs"
        v-on="on"
        text
        @click="fnConsultarInformacionDeGuia"
      >
        <v-icon x-small> mdi-eye-outline </v-icon>
      </v-btn>
    </template>
    <v-card v-if="guiaid">
      <v-card-title>
        <span class="headline">Detalles Generales de guía </span>
      </v-card-title>
      <v-card-text>
        <cuerpo :guia="selectedGuia"></cuerpo>
        <small> Datakalan S.A.</small>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <!-- <v-btn color="blue darken-1" text @click="dialog = false">
          Close
        </v-btn> -->
        <v-btn color="blue darken-1" text @click="dialog = false">
          Cerrar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import cuerpo from './modalInformacionDeGuiaCuerpo';
import { mapState, mapGetters, mapActions } from 'vuex';
export default {
  props: {
    guiaid: {
      type: String,
    },
  },
  components: {
    cuerpo,
  },
  data() {
    return {
      selectedGuia: [],
      //
      loading: false,
      dialog: false,
      /** */
      admins: [
        ['Management', 'mdi-account-multiple-outline'],
        ['Settings', 'mdi-cog-outline'],
      ],
      cruds: [
        ['Create', 'mdi-plus-outline'],
        ['Read', 'mdi-file-outline'],
        ['Update', 'mdi-update'],
        ['Delete', 'mdi-delete'],
      ],
    };
  },
  methods: {
    ...mapActions('Gpsguias', {
      getguias: 'get',
      findguias: 'find',
      patchGuia: 'patch',
      crearGuia: 'create',
    }),
    fnConsultarInformacionDeGuia() {
      this.getguias(this.guiaid).then((result) => {
        this.selectedGuia = result;
        console.log('información de | modal guia | cuepro:');
        console.log(this.selectedGuia);
      });
    },
  },
};
</script>
<style scoped>
th,
td {
  border-bottom: 1px solid #ddd;
}
td:nth-child(2) {
  font-size: 12px;
}
tr:nth-child(odd) {
  background-color: rgba(255, 255, 255, 0.659);
}
tr:hover {
  background-color: rgba(201, 196, 196, 0.672);
}
.tdTituloDivision {
  font-size: 16px;
}
</style>
