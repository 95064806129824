<template>
  <v-container fluid>
    <material-card :color="taskColor" full-header>
      <v-card-text>
        <!-- /** tabla dashboard de guias segun estado durante un mes */ -->
        <DashboardTablaGuias
          :prop_fecha="$store.state.globalMonth"
          :guiaStatus="db_label"
        />
      </v-card-text>
      <guiaCrear></guiaCrear>
    </material-card>
  </v-container>
</template>

<script>
import guiaCrear from './guias_crear.vue';
import DashboardTablaGuias from '../DashboardContent/DashboardTablaGuias.vue';
export default {
  name: 'guias',
  components: {
    guiaCrear,
    DashboardTablaGuias,
  },
  data() {
    return {
      taskColor: 'primary',
      db_label: '',
    };
  },
};
</script>
